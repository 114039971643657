import { Navigate, useRoutes, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
import { PATH_BEFORE_LOGIN } from 'src/config-global';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

export default function Router() {
  return useRoutes([
    // before login route
    {
      path: '/',
      // element: (
      //   <MainLayout>
      //     <Outlet />
      //   </MainLayout>
      // ),
      children: [{ element: <Navigate to={PATH_BEFORE_LOGIN} replace />, index: true }],
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
