import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const styles = {
    st0: {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    },
    st1: {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      fill: '#FFFFFF',
    },
    st2: {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      fill: '#ED2129',
    },
  };

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 200 550 400"
        style={{ enableBackground: 'new 0 0 612 792' }}
        xmlSpace="preserve"
      >
        <style>{`
        .st0 { fill-rule: ${styles.st0.fillRule}; clip-rule: ${styles.st0.clipRule}; }
        .st1 { fill-rule: ${styles.st1.fillRule}; clip-rule: ${styles.st1.clipRule}; fill: ${styles.st1.fill}; }
        .st2 { fill-rule: ${styles.st2.fillRule}; clip-rule: ${styles.st2.clipRule}; fill: ${styles.st2.fill}; }
      `}</style>
        <polygon
          className="st0"
          points="221.98,234.74 229.55,241.52 274.45,241.52 313.73,285.01 355.13,285.01 444.21,363.59 491.21,341.14 
	554.35,435.85 161.56,435.85 57,577.18 149.47,403.66 79.31,446.91 210.47,216 221.98,234.74 "
        />
        <path
          className="st0"
          d="M481.01,449.08h12.82l17.28,33.64h0.21l-0.64-33.64h7.78v40.24h-12.93l-17.07-33.64h-0.31l0.44,16.69
	l0.21,16.96h-7.78V449.08L481.01,449.08z M462.47,449.08h7.78v40.24h-7.78V449.08L462.47,449.08z M430.41,449.08h11.32l13.74,40.24
	h-7.89l-2.57-7.57h-17.45l-2.58,7.57H417l9.23-27.9L430.41,449.08L430.41,449.08z M429.29,475.91h13.9l-6.92-20.99h-0.05
	L429.29,475.91L429.29,475.91z M397.03,489.32v-33.63h-11.75v-6.61h31.32v6.61h-11.85v33.63H397.03L397.03,489.32z M340.51,449.08
	h12.82l17.28,33.64h0.21l-0.64-33.64h7.78v40.24h-12.93l-17.07-33.64h-0.31l0.44,16.69l0.21,16.96h-7.78V449.08L340.51,449.08z
	 M312.53,483.04c0.64,0,1.11,0.04,1.4,0.11c1.89-0.06,3.33-0.19,4.31-0.37c0.99-0.18,1.82-0.55,2.5-1.14
	c0.58-0.48,0.95-1.06,1.1-1.69c0.15-0.64,0.23-1.67,0.23-3.08v-27.79h7.73v28.82c0,4.24-1.18,7.31-3.54,9.22
	c-1.42,1.12-3.33,1.82-5.71,2.1c-2.39,0.29-5.28,0.43-8.67,0.43c-2.48,0-4.6-0.12-6.38-0.36c-1.76-0.23-3.38-0.74-4.84-1.53
	c-1.49-0.89-2.6-2.22-3.32-4.01c-0.73-1.78-1.08-3.81-1.08-6.08v-28.6h7.78v29.23c0,0.97,0.11,1.69,0.32,2.2
	c0.21,0.5,0.63,0.95,1.23,1.35c0.76,0.52,1.65,0.84,2.67,0.97C309.3,482.97,310.72,483.04,312.53,483.04L312.53,483.04z
	 M269.74,448.75c2.1,0,4.05,0.09,5.87,0.26c1.82,0.17,3.54,0.56,5.18,1.18c1.65,0.63,2.86,1.54,3.66,2.75
	c0.42,0.46,0.95,1.48,1.58,3.08c0.63,1.59,0.95,3.93,0.95,7.01v12.99c0,2.42-0.23,4.3-0.69,5.63c-0.46,1.32-1.11,2.59-1.94,3.81
	c-0.93,1.28-2.24,2.23-3.94,2.84c-1.7,0.62-3.43,1-5.23,1.14c-1.79,0.13-3.94,0.21-6.46,0.21c-3.07,0-5.65-0.22-7.75-0.67
	c-2.1-0.45-3.81-1.39-5.12-2.83c-2.04-2.04-3.06-5.81-3.06-11.32v-11.01c0-2.33,0.15-4.4,0.46-6.22c0.3-1.82,0.91-3.41,1.85-4.77
	c0.88-1.23,2.12-2.14,3.74-2.74c1.61-0.6,3.28-0.97,5.01-1.13C265.57,448.82,267.53,448.75,269.74,448.75L269.74,448.75z
	 M269.85,455.35c-2.5,0-4.42,0.18-5.77,0.55c-1.35,0.36-2.25,1.06-2.7,2.08c-0.53,1.16-0.87,2.95-1.03,5.38
	c0.07,0.31,0.11,0.72,0.11,1.23v9.54c0,3.3,0.38,5.57,1.13,6.81c0.63,0.93,1.53,1.52,2.7,1.74c1.17,0.23,2.97,0.38,5.41,0.46
	c2.36-0.05,4.14-0.23,5.35-0.55c1.22-0.3,2.15-0.86,2.82-1.65c0.39-0.58,0.71-1.42,0.96-2.53c0.25-1.12,0.38-2.32,0.38-3.59v-10.68
	v-1.45c0-2.54-0.45-4.36-1.33-5.46c-0.89-0.94-2.28-1.53-4.19-1.78C272.49,455.39,271.22,455.35,269.85,455.35L269.85,455.35z
	 M196.28,489.32v-40.24h12.51l10.88,29.88h0.21l10.79-29.88h12.65v40.24h-7.72l0.64-33.96h-0.22l-12.7,33.96h-6.93l-12.77-33.74
	h-0.21l0.44,14.95l0.21,18.79H196.28L196.28,489.32z"
        />
        <polygon
          className="st1"
          points="466.46,420.17 466.46,379.93 494.09,379.93 494.09,386.53 474.18,386.53 474.18,396.61 493.07,396.61 
	493.07,402.89 474.18,402.89 474.18,413.56 494.19,413.56 494.19,420.17 466.46,420.17 "
        />
        <polygon
          className="st1"
          points="437.73,420.17 437.73,404.76 423.13,379.93 431.94,379.93 441.48,397.1 441.7,397.1 451.47,379.93 
	460.37,379.93 445.45,404.76 445.45,420.17 437.73,420.17 "
        />
        <polygon
          className="st1"
          points="389.74,420.17 389.74,379.93 417.37,379.93 417.37,386.53 397.47,386.53 397.47,396.61 416.35,396.61 
	416.35,402.89 397.47,402.89 397.47,413.56 417.48,413.56 417.48,420.17 389.74,420.17 "
        />
        <polygon
          className="st1"
          points="348.09,379.93 355.86,379.93 355.86,396.39 359.52,396.39 372.94,379.93 382.37,379.93 366.11,399.62 
	384.03,420.17 374.32,420.17 359.52,403 355.86,403 355.86,420.17 348.09,420.17 348.09,379.93 "
        />
        <path
          className="st1"
          d="M331.06,393.45v-2.51c0-1.63-0.39-2.81-1.18-3.55c-0.82-0.79-3.05-1.18-6.7-1.18h-1.13
	c-2.05,0-3.59,0.13-4.63,0.37c-1.05,0.24-1.89,0.68-2.5,1.3c-1.11,1.07-1.66,3.31-1.66,6.71v10.51c0,4.03,0.66,6.5,1.99,7.4
	c0.79,0.63,1.73,1.01,2.79,1.17c1.07,0.15,2.55,0.23,4.44,0.23c2.29,0,3.83-0.04,4.61-0.11c1.39-0.27,2.42-0.73,3.12-1.4
	c0.31-0.32,0.55-0.72,0.73-1.2c0.17-0.47,0.29-0.95,0.35-1.44c0.06-0.48,0.11-1.11,0.13-1.86c0.04-0.76,0.05-1.46,0.07-2.1h7.62
	v2.52v0.97c0,3.93-0.98,6.79-2.96,8.58c-1.74,1.38-4.38,2.19-7.93,2.42c-1.25,0.1-2.78,0.17-4.61,0.21
	c-1.82,0.05-2.88,0.09-3.17,0.11c-5.54,0-9.41-1.09-11.59-3.27c-1.03-1.03-1.77-2.33-2.24-3.91c-0.47-1.58-0.76-3.3-0.86-5.17
	c-0.11-1.87-0.16-4.5-0.16-7.88c0-3.45,0.04-6.07,0.13-7.83c0.1-1.77,0.35-3.38,0.76-4.83c0.42-1.45,1.1-2.79,2.06-4.02
	c0.92-1.14,2.15-2,3.68-2.6c1.53-0.61,3.16-1,4.87-1.2c1.73-0.19,3.57-0.29,5.54-0.29c2.24,0,4.06,0.04,5.47,0.13
	c1.41,0.08,2.79,0.3,4.12,0.67c1.33,0.37,2.44,0.95,3.33,1.74c0.97,0.87,1.67,1.96,2.13,3.26c0.45,1.3,0.73,2.57,0.83,3.81
	c0.11,1.24,0.16,2.66,0.16,4.26H331.06L331.06,393.45z"
        />
        <path
          className="st1"
          d="M279.04,413.89c0.64,0,1.11,0.04,1.4,0.11c1.89-0.06,3.33-0.19,4.31-0.37c0.99-0.18,1.82-0.55,2.5-1.14
	c0.58-0.48,0.95-1.06,1.1-1.69c0.15-0.64,0.23-1.67,0.23-3.08v-27.79h7.73v28.82c0,4.24-1.18,7.31-3.54,9.22
	c-1.42,1.12-3.33,1.82-5.71,2.1c-2.39,0.29-5.28,0.43-8.67,0.43c-2.48,0-4.6-0.12-6.38-0.36c-1.76-0.23-3.38-0.74-4.84-1.53
	c-1.49-0.89-2.6-2.22-3.32-4.01c-0.72-1.78-1.08-3.81-1.08-6.08v-28.6h7.78v29.23c0,0.97,0.11,1.69,0.32,2.2
	c0.21,0.5,0.63,0.95,1.23,1.35c0.76,0.52,1.65,0.84,2.67,0.97C275.81,413.82,277.23,413.89,279.04,413.89L279.04,413.89z"
        />
        <path
          className="st1"
          d="M240.66,379.93c4.65,0,7.76,0.84,9.34,2.53c1.61,1.45,2.41,4.1,2.41,7.94c0,3.04-0.55,5.28-1.67,6.71
	c-0.88,1.05-2.24,1.78-4.07,2.2v0.21c2.01,0.23,3.51,0.85,4.51,1.87c0.98,1.02,1.6,2.14,1.84,3.36c0.24,1.23,0.36,2.64,0.36,4.21
	c0,4.35-0.95,7.31-2.84,8.91c-1.74,1.54-4.78,2.31-9.13,2.31h-20.92v-40.24H240.66L240.66,379.93z"
        />
        <path
          className="st0"
          d="M238.89,386.53h-10.68v10.24h10.57c1.22,0,2.05-0.04,2.51-0.11c1.31-0.22,2.19-0.81,2.67-1.77
	c0.47-0.96,0.71-2.21,0.71-3.76c0-1.49-0.25-2.62-0.74-3.37c-0.38-0.47-0.92-0.8-1.64-0.97
	C241.59,386.62,240.45,386.53,238.89,386.53L238.89,386.53z"
        />
        <path
          className="st0"
          d="M228.21,402.69v10.87h11.54c1.41,0,2.51-0.11,3.32-0.31c1.68-0.61,2.53-2.25,2.53-4.94
	c0-2.22-0.35-3.71-1.03-4.5c-0.79-0.75-2.25-1.13-4.4-1.13H228.21L228.21,402.69z"
        />
        <polygon
          className="st2"
          points="210.61,223.98 90.65,435.15 160.1,392.35 86.44,530.56 240.07,322.9 165.01,358.68 235.16,263.96 
	210.61,223.98 "
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
