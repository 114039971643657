import PropTypes from 'prop-types'; // @mui
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------

export default function CircularLoadingScreen({ sx, ...other }) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      <CircularProgress />
    </Box>
  );
}

CircularLoadingScreen.propTypes = {
  sx: PropTypes.object,
};
